import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CreateDealContext } from "screens/create-deal/context";
import { isSyndicateDraft } from "screens/create-deal/utils";

import { FormValues } from "../../utils";

export function useProxyVotingField() {
  const { currentDraft } = useContext(CreateDealContext);
  const { watch } = useFormContext<FormValues>();

  const isFounderDeal = isSyndicateDraft(currentDraft.pricing_plan) === false;
  const fieldValue = watch("proxy_voting_rights");

  return {
    isFounderDeal,
    fieldValue,
  };
}
