import { DraftTermsEquityShareClass } from "@internal/rest/generated/schemas";
import { Form } from "components/forms/Form/Form";
import { validateAmount } from "screens/create-deal/utils";
import { SHARE_PRICE_DECIMAL_LIMIT } from "utility/consts";

import { inputMaxWidth } from "../../DealTermsStep";
import { FormFields, FormLabels } from "../../utils";
import { useEquityFields } from "./useEquityFields";

export function EquityFields() {
  const { isVisible, currencySign, withSeis, isSecondaryDeal } =
    useEquityFields();

  return isVisible ? (
    <>
      {withSeis ? (
        <Form.Select
          name={FormFields.ShareClassCommon}
          label={FormLabels.ShareClass}
          invalidErrorMessage="Please select a share class"
          selectContainerStyle={{ maxWidth: inputMaxWidth }}
          fieldOpts={{ required: true }}
        >
          <option value={DraftTermsEquityShareClass.common}>
            Common/Ordinary
          </option>
        </Form.Select>
      ) : (
        <Form.Select
          name={FormFields.ShareClass}
          label={FormLabels.ShareClass}
          invalidErrorMessage="Please select a share class"
          selectContainerStyle={{ width: inputMaxWidth }}
          fieldOpts={{ required: true }}
        >
          <option value={DraftTermsEquityShareClass.common}>
            Common/Ordinary
          </option>

          <option value={DraftTermsEquityShareClass.preferred}>
            Preferred
          </option>
        </Form.Select>
      )}

      <Form.AmountField
        name={FormFields.SharePrice}
        label={FormLabels.SharePrice}
        subLabel={
          <span>
            If not finalised, please enter 1.00.
            <br />
            This can be updated before or at closing and will not be shown to
            investors until the deal is closed.
          </span>
        }
        placeholder="100"
        leftSymbol={currencySign}
        invalidMessage={`Please specify a share price`}
        inputStyle={{ width: inputMaxWidth }}
        decimalsLimit={SHARE_PRICE_DECIMAL_LIMIT}
        validate={(val) =>
          validateAmount({
            amount: val,
            minAllowed: 0.0001,
          })
        }
      />

      {!isSecondaryDeal ? (
        <Form.AmountField
          label={FormLabels.PreMoney}
          name={FormFields.PreMoney}
          placeholder="25,000,000"
          leftSymbol={currencySign}
          invalidMessage={`Please specify a pre-money valuation of at least ${currencySign}1,000`}
          inputStyle={{ width: inputMaxWidth }}
          validate={(val) =>
            validateAmount({
              amount: val,
            })
          }
        />
      ) : null}
    </>
  ) : null;
}
