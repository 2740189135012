import { Box, VStack } from "@@panda/jsx";
import { Card } from "components/display/Card";
import { Typography } from "components/display/Typography/Typography";
import { Form } from "components/forms/Form/Form";
import { Button } from "components/main/Button";
import { Dialog } from "components/main/Dialog";
import { useAuth } from "services/auth/AuthProvider";

import { FormTermsSignedOn } from "./FormTermsSignedOn";
import { TermsContent } from "./TermsContent";
import { FormValues } from "./helpers";

interface Props {
  onSubmit: (signature: string) => void;
  isSubmitting: boolean;
}

export function SubmitTermsModal({ onSubmit, isSubmitting }: Props) {
  const { account } = useAuth();
  const { first_name = "", last_name = "" } = account || {};

  return (
    <Dialog title="Syndicate Lead Agreement">
      <Dialog.Trigger>
        <Button mt="xl" isLoading={isSubmitting}>
          Submit for review
        </Button>
      </Dialog.Trigger>

      <Dialog.Content>
        <Typography variant="body" mb="s">
          Please review and accept the terms below to submit your deal for
          review on Odin.
        </Typography>
        <Form onSubmit={(data: FormValues) => onSubmit(data.signature)}>
          <VStack
            alignItems="flex-start"
            gap="m"
            data-testid="submit-draft-terms-modal"
          >
            <Card
              width="full"
              textAlign="left"
              py="l"
              px="m"
              data-testid="terms-card"
              withBorder
            >
              <TermsContent />
            </Card>
            <Box w="full">
              <Form.Signature
                firstName={first_name}
                lastName={last_name}
                name="signature"
                label="Signature*"
              />
              <FormTermsSignedOn />
              <Typography variant="body" color="grey.gunsmoke" mt="l">
                By clicking submit, I accept these terms and conditions.
              </Typography>
            </Box>
            <Form.Submit isLoading={isSubmitting}>
              Accept Syndicate Terms & Submit
            </Form.Submit>
          </VStack>
        </Form>
      </Dialog.Content>
    </Dialog>
  );
}
