import { Box, Stack, styled } from "@@panda/jsx";
import { BaseCurrency, PricePlanName } from "@internal/rest/generated/schemas";
import { PricePlanCard } from "components/display/PricePlanCard";
import { Typography } from "components/display/Typography/Typography";
import { Link } from "components/interaction/Link";
import { activeSyndicatePricePlans } from "utility/pricing";

import { CREATE_NEW_SYNDICATE_OPTION } from "../../utils";
import { usePricePlans } from "./usePricePlans";

interface Props {
  active: PricePlanName;
  syndicatePlan: PricePlanName;
  currency: BaseCurrency;
  onPricePlanChange: (pricePlan: PricePlanName) => void;
  currentSyndicateId: string;
  isSubscribed: boolean;
}

export function PricePlans({
  active,
  onPricePlanChange,
  syndicatePlan,
  isSubscribed,
  currentSyndicateId,
}: Props) {
  const { onSelectPricePlan, isMdScreenUp } = usePricePlans({
    onPricePlanChange,
  });

  const isCreatingSyndicate =
    currentSyndicateId === CREATE_NEW_SYNDICATE_OPTION;

  const isUpgraded = isSubscribed && !isCreatingSyndicate;

  return (
    <Box mb="2.5rem">
      <Box mt="xl" mb="0">
        <styled.p fontSize="sm" fontWeight={500} color="grey.iron" mb="m">
          Subscription tier
        </styled.p>
      </Box>
      {isUpgraded ? (
        <Box w="16rem">
          <PricePlanCard
            onClick={onSelectPricePlan}
            pricePlan={syndicatePlan}
            selected={false}
            narrow
            readonly
          />
        </Box>
      ) : (
        <Stack
          flexDir={isMdScreenUp ? "row" : "column"}
          gap="m"
          alignItems="flex-start"
        >
          {activeSyndicatePricePlans.map((plan) => (
            <Box w={{ base: "full", lg: "14rem" }} key={plan}>
              <PricePlanCard
                onClick={onSelectPricePlan}
                pricePlan={plan}
                selected={active === plan}
                narrow={isMdScreenUp}
              />
            </Box>
          ))}
        </Stack>
      )}
      {isUpgraded ? (
        <Typography variant="body" mt="4">
          <Link
            href="mailto:hello@joinodin.com?subject=Change subscription tier"
            isExternal
          >
            Contact us
          </Link>{" "}
          to discuss changes to your price plan.
        </Typography>
      ) : null}
    </Box>
  );
}
