export const USER_AGREEMENT_URL =
  "https://file.notion.so/f/f/482912e4-4344-464c-8701-238c0ef01ea7/4a2f7d66-a360-4b31-8662-79e5208335e5/2.5_Odin_Master_Platform_User_Agreement__Custody_Terms_07.10.2024.pdf?table=block&id=2b8d1275-f5db-470d-842c-a40bf1aa5b5d&spaceId=482912e4-4344-464c-8701-238c0ef01ea7&expirationTimestamp=1729850400000&signature=dYdisYXFV4uyAgMsvOHGlfvp0QpexDNbvgfvGk7RRh8&downloadName=2.5+Odin+Master+Platform+User+Agreement+%2B+Custody+Terms+07.10.2024.pdf";

export const SYND_TERMS_URL =
  "https://file.notion.so/f/f/482912e4-4344-464c-8701-238c0ef01ea7/f722c683-1868-4501-97c7-df8239873095/2._Odin_Master_Syndicate_Terms_07.10.2024.pdf?table=block&id=8d87f2da-431a-49c8-a4a8-385dec49387f&spaceId=482912e4-4344-464c-8701-238c0ef01ea7&expirationTimestamp=1729850400000&signature=CwKyu-tPYgrwsykpeIfpbPO4VevOrrCqkwJylMRYZQI&downloadName=2.+Odin+Master+Syndicate+Terms+07.10.2024.pdf";

export const SL_AGREEMENT_URL =
  "https://file.notion.so/f/f/482912e4-4344-464c-8701-238c0ef01ea7/2f366d94-9ac0-437d-8ec6-7d96fc337950/3._Odin_Master_Syndicate_Lead_Agreement_07.10.2024.docx.pdf?table=block&id=12921b33-eab9-80e8-855c-cab569c5d549&spaceId=482912e4-4344-464c-8701-238c0ef01ea7&expirationTimestamp=1729900800000&signature=qVOoBMTKahjc3Ll-Jc7ndJHNkOKny_q01IoRMymn5D8&downloadName=3._Odin_Master_Syndicate_Lead_Agreement_07.10.2024.docx.pdf";

export type FormValues = {
  signature: string;
};
