import { Box } from "@@panda/jsx";
import { Alert } from "components/feedback/Alert";
import { Link } from "components/interaction/Link";

type Props = {
  isFounderDeal: boolean;
};

export function DoNotProxyWarning(props: Props) {
  const syndicateCopy = (
    <Box>
      Please note: All investors will need to confirm consent to any future
      legal document from the investee company before Odin can sign it{" "}
      <Link
        isExternal
        href="https://help.joinodin.com/hc/en-gb/articles/25326486288273-Drafting-a-primary-deal#:~:text=according%20to%20urgency.-,Proxy%20voting%20rights,-%2D%20Here%20you%20can"
      >
        (more info).
      </Link>{" "}
      If you want to sign legal documents on their behalf, please select{" "}
      <strong>'You, the Syndicate Lead(s)'</strong>.
    </Box>
  );

  const founderCopy = (
    <Box>
      Please note: All investors will need to confirm consent to any future
      legal document from your company before Odin can sign it{" "}
      <Link
        isExternal
        href="https://help.joinodin.com/hc/en-gb/articles/27892237793681-Drafting-a-Deal#:~:text=accordingly%20to%20urgency.-,Proxy%20voting%20rights,-%3A%20Here%20you%20can"
      >
        (more info).
      </Link>{" "}
      If you want to sign legal documents on their behalf, please select{" "}
      <strong>'Founder(s) of the Investee company' </strong>.
    </Box>
  );

  return (
    <Alert variant="info">
      {props.isFounderDeal ? founderCopy : syndicateCopy}
    </Alert>
  );
}
